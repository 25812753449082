import { useContext } from 'react';
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Icon,
  Typography,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { dropRegraIntegracao } from 'utils/drops';
import { LançamentosContext } from 'contexts/LançamentosContext';
import { useModal } from 'components/Modals';
import Button from 'components/Button';
import Dropdown from 'components/Dropdown';

const ContabilizarModal = ({ onSubmit, text }) => {
  const { closeModal } = useModal();
  const { handleSubmit } = useForm();
  const { postLoading } = useContext(LançamentosContext);

  return (
    <>
      <DialogContent sx={{ textAlign: 'center' }}>
        <DialogContentText>
          <Icon sx={{ fontSize: 150 }}>warning</Icon>
        </DialogContentText>
        <DialogContentText>
          Deseja realmente {text} todos os lançamentos filtrados?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          loading={postLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Confirmar
        </Button>
        <Button color="secondary" variant="contained" onClick={closeModal}>
          Cancelar
        </Button>
      </DialogActions>
    </>
  );
};

export default ContabilizarModal;
