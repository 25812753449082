import React, { useContext, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import { decryptURL } from 'utils/functions';
import { AuthContext } from 'contexts/AuthContext';
import { GridContext } from 'contexts/GridContext';
import { DropsContext } from 'contexts/DropsContext';
import Container from 'components/Container';
import InputMask from 'components/InputMask';
import Dropdown from 'components/Dropdown';
import Button from 'components/Button';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import moment from 'moment';

const Gerar = () => {
  const rotina = 'LançamentosContabil';
  const navigate = useNavigate();
  const defaultValues = {
    planoentidade_id: null,
    valor: '',
    documento: '',
    dtlanc: moment().format('YYYY-MM-DD'),
    ctacred_id: null,
    ctadeb_id: null,
    ccustocred_id: null,
    ccustodeb_id: null,
    historico: '',
    contabilizado: false,
  };
  const { control, handleSubmit, setValue } = useForm({ defaultValues });
  const { postLoading, postGrid } = useContext(GridContext);
  const { drops } = useContext(DropsContext);
  const { user } = useContext(AuthContext);
  const { hash = '' } = useParams();
  const decrypt = decryptURL(hash);

  useEffect(() => {
    if (Boolean(decrypt)) {
      setValue('planoentidade_id', decrypt?.planoentidade_id);
      setValue('valor', decrypt?.valor);
      setValue('documento', decrypt?.documento);
      setValue('dtlanc', decrypt?.dtlanc);
      setValue('ctacred_id', decrypt?.ctacred_id);
      setValue('ctadeb_id', decrypt?.ctadeb_id);
      setValue('ccustocred_id', decrypt?.ccustocred_id);
      setValue('ccustodeb_id', decrypt?.ccustodeb_id);
      setValue('historico', decrypt?.historico);
      setValue('contabilizado', Boolean(decrypt?.contabilizado === 'SIM'));
    }
  }, [decrypt]);

  const onSubmit = (values) => {
    const data = {
      ...values,
      id: decrypt?.id,
      users_id: user?.id,
      datahora: moment().format('YYYY-MM-DD HH:mm:ss'),
      contabilizado: Boolean(values?.contabilizado) ? 'SIM' : 'NAO',
    };
    postGrid({ data: { data, rotina }, cb: () => navigate(-1) });
  };

  return (
    <Container>
      <Header
        titulo={(Boolean(hash) ? 'Editar' : 'Adicionar') + ' Lançamento'}
      />
      <Card>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Dropdown
              name="planoentidade_id"
              control={control}
              label="Plano / Entidade"
              options={drops?.Planoentidade}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask name="valor" control={control} label="Valor" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input name="documento" control={control} label="Documento" />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="dtlanc"
              control={control}
              label="Data de Lançamento"
              type="date"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="ctadeb_id"
              control={control}
              label="Conta de Débito"
              options={drops?.Planoconta?.filter(
                (p) => p?.tipo === 'ANALITICA'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="ctacred_id"
              control={control}
              label="Conta de Crédito"
              options={drops?.Planoconta?.filter(
                (p) => p?.tipo === 'ANALITICA'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="ccustocred_id"
              control={control}
              label="Centro de Custo de Crédito"
              options={drops?.Ccusto}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="ccustodeb_id"
              control={control}
              label="Centro de Custo de Débito"
              options={drops?.Ccusto}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="historico"
              control={control}
              label="Histórico"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="contabilizado"
              control={control}
              render={({ field: { onChange, value } }) => (
                <FormControlLabel
                  control={<Switch checked={value} onChange={onChange} />}
                  label="Contabilizado"
                />
              )}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit(onSubmit)}
              loading={postLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
};

export default Gerar;
