import { Check, Close } from '@mui/icons-material';
import { Box, Tooltip, Typography } from '@mui/material';
import moment from 'moment';

const StatusCell = ({ value }) => {
  const props = {
    SIM: { label: 'Contabilizado', icon: <Check color="success" /> },
    NAO: { label: 'Não Contabilizado', icon: <Close color="error" /> },
  };

  return (
    <Tooltip sx={{ flex: 1 }} title={props[value]?.label}>
      {props[value]?.icon}
    </Tooltip>
  );
};

const LancamentoCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Nº do Documento',
      value: row?.documento,
    },
    {
      label: 'Valor',
      value: row?.valor?.toLocaleString('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }),
    },
    {
      label: 'Data',
      value:
        row?.datahora && moment(row?.datahora).format('DD/MM/YYYY [às] HH:mm'),
    },
  ];

  let str = '';
  const hasUser = Boolean(row?.usuario);
  const hasDh = Boolean(row?.datahora) && moment(row?.datahora).isValid();
  if (hasUser && hasDh) {
    str = `Lançado por ${row?.usuario}`;
  }

  return (
    <Box flex={1} display="flex" flexDirection="column">
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={1} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
      {Boolean(str) && (
        <Typography variant="caption" textAlign="start">
          {str}
        </Typography>
      )}
    </Box>
  );
};

const ContasCell = ({ row = {} }) => {
  const lines = [
    {
      label: 'Débito',
      value: row?.ctadeb,
    },
    {
      label: 'Crédito',
      value: row?.ctacred,
    },
    {
      label: 'Regra Utilizada',
      value: row?.regra,
    },
  ];
  return (
    <Box flex={1}>
      {lines?.map(
        ({ label, value }, i) =>
          Boolean(value) && (
            <Box key={i?.toString()} display="flex">
              <Typography flex={1} variant="caption" fontWeight="bold">
                {label}:{' '}
              </Typography>
              <Typography flex={5} variant="caption">
                {value}
              </Typography>
            </Box>
          )
      )}
    </Box>
  );
};

const mock = [
  {
    field: 'contabilizado',
    renderCell: (props) => <StatusCell {...props} />,
  },
  {
    field: 'lancamento',
    renderCell: (props) => <LancamentoCell {...props} />,
  },
  {
    field: 'contas',
    renderCell: (props) => <ContasCell {...props} />,
  },
];

export default mock;
